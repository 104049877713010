/* .App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .App-logo {
   height: 40vmin; 
  pointer-events: none;
} */


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10050s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/*==================== BANNER MEDIA QUERRY =========================*/


.goog-te-combo option:nth-child(46),
 .goog-te-combo option:nth-child(117),
 .goog-te-combo option:nth-child(115),
 .goog-te-combo option:nth-child(105),
 .goog-te-combo option:nth-child(99),
 .goog-te-combo option:nth-child(94),
 .goog-te-combo option:nth-child(88),
 .goog-te-combo option:nth-child(86),
 .goog-te-combo option:nth-child(81),
 .goog-te-combo option:nth-child(78),
 .goog-te-combo option:nth-child(75),
 .goog-te-combo option:nth-child(57),
 .goog-te-combo option:nth-child(41),
 .goog-te-combo option:nth-child(14),
 .goog-te-combo option:nth-child(13),
 .goog-te-combo option:nth-child(7){
    display:block;
  }





  .goog-te-combo option:nth-child(1),
  .goog-te-combo option:nth-child(2),
  .goog-te-combo option:nth-child(3),
  .goog-te-combo option:nth-child(4),
  .goog-te-combo option:nth-child(5),
  .goog-te-combo option:nth-child(6),
  .goog-te-combo option:nth-child(8),
  .goog-te-combo option:nth-child(9),
  .goog-te-combo option:nth-child(10),
  .goog-te-combo option:nth-child(11),
  .goog-te-combo option:nth-child(12),
  .goog-te-combo option:nth-child(15),
  .goog-te-combo option:nth-child(16),
  .goog-te-combo option:nth-child(17),
  .goog-te-combo option:nth-child(18),
  .goog-te-combo option:nth-child(19),
  .goog-te-combo option:nth-child(20),
  .goog-te-combo option:nth-child(21),
  .goog-te-combo option:nth-child(22),
  .goog-te-combo option:nth-child(23),
  .goog-te-combo option:nth-child(24),
  .goog-te-combo option:nth-child(25),
  .goog-te-combo option:nth-child(26),
  .goog-te-combo option:nth-child(27),
  .goog-te-combo option:nth-child(28),
  .goog-te-combo option:nth-child(29),
  .goog-te-combo option:nth-child(30),
  .goog-te-combo option:nth-child(31),
  .goog-te-combo option:nth-child(32),
  .goog-te-combo option:nth-child(33),
  .goog-te-combo option:nth-child(34),
  .goog-te-combo option:nth-child(35),
  .goog-te-combo option:nth-child(36),
  .goog-te-combo option:nth-child(37),
  .goog-te-combo option:nth-child(38),
  .goog-te-combo option:nth-child(39),
  .goog-te-combo option:nth-child(40),
  .goog-te-combo option:nth-child(42),
  .goog-te-combo option:nth-child(43),
  .goog-te-combo option:nth-child(44),
  .goog-te-combo option:nth-child(45),
 
  .goog-te-combo option:nth-child(47),
  .goog-te-combo option:nth-child(48),
  .goog-te-combo option:nth-child(49),
  .goog-te-combo option:nth-child(50),
  .goog-te-combo option:nth-child(50),
  .goog-te-combo option:nth-child(51),
  .goog-te-combo option:nth-child(52),
  .goog-te-combo option:nth-child(53),
  .goog-te-combo option:nth-child(54),
  .goog-te-combo option:nth-child(55),
  .goog-te-combo option:nth-child(56),
  .goog-te-combo option:nth-child(58),
  .goog-te-combo option:nth-child(59),
  .goog-te-combo option:nth-child(60),
  .goog-te-combo option:nth-child(61),
  .goog-te-combo option:nth-child(62),
  .goog-te-combo option:nth-child(63),
  .goog-te-combo option:nth-child(64),
  .goog-te-combo option:nth-child(65),
  .goog-te-combo option:nth-child(66),
  .goog-te-combo option:nth-child(67),
  .goog-te-combo option:nth-child(68),
  .goog-te-combo option:nth-child(69),
  .goog-te-combo option:nth-child(70),
  .goog-te-combo option:nth-child(71),
  .goog-te-combo option:nth-child(72),
  .goog-te-combo option:nth-child(73),
  .goog-te-combo option:nth-child(74),
  .goog-te-combo option:nth-child(76),
  .goog-te-combo option:nth-child(77),
  .goog-te-combo option:nth-child(79),
  .goog-te-combo option:nth-child(80),
  .goog-te-combo option:nth-child(82),
  .goog-te-combo option:nth-child(83),
  .goog-te-combo option:nth-child(84),
  .goog-te-combo option:nth-child(85),
  .goog-te-combo option:nth-child(87),
  .goog-te-combo option:nth-child(89),
  .goog-te-combo option:nth-child(90),
  .goog-te-combo option:nth-child(91),
  .goog-te-combo option:nth-child(92),
  .goog-te-combo option:nth-child(93),
  .goog-te-combo option:nth-child(95),
  .goog-te-combo option:nth-child(96),
  .goog-te-combo option:nth-child(97),
  .goog-te-combo option:nth-child(98),
  .goog-te-combo option:nth-child(100),
  .goog-te-combo option:nth-child(101),
  .goog-te-combo option:nth-child(102),
  .goog-te-combo option:nth-child(103),
  .goog-te-combo option:nth-child(104),
  .goog-te-combo option:nth-child(106),
  .goog-te-combo option:nth-child(107),
  .goog-te-combo option:nth-child(108),
  .goog-te-combo option:nth-child(109),
  .goog-te-combo option:nth-child(110),
  .goog-te-combo option:nth-child(111),
  .goog-te-combo option:nth-child(112),
  .goog-te-combo option:nth-child(113),
  .goog-te-combo option:nth-child(114),
  .goog-te-combo option:nth-child(116),
  .goog-te-combo option:nth-child(118),
  .goog-te-combo option:nth-child(119),
  .goog-te-combo option:nth-child(120),
  .goog-te-combo option:nth-child(121),
  .goog-te-combo option:nth-child(122),
  .goog-te-combo option:nth-child(123),
  .goog-te-combo option:nth-child(124),
  .goog-te-combo option:nth-child(125),
  .goog-te-combo option:nth-child(126),
  .goog-te-combo option:nth-child(127),
  .goog-te-combo option:nth-child(128),
  .goog-te-combo option:nth-child(129),
  .goog-te-combo option:nth-child(130),
  .goog-te-combo option:nth-child(131),
  .goog-te-combo option:nth-child(132),
  .goog-te-combo option:nth-child(133) {
    display: none;
  }




/* ----------------------------------------------------------------------PANKAJSIR------------------------------------------------------------------------------------- */

.con center {
  background: linear-gradient(181deg, #7963d2, transparent);
    padding: 20px;
    border-radius: 20px;
}

.cons center {
  background: linear-gradient(181deg, #7963d2, #060e04);
  padding: 20px;
  border-radius: 20px;
}
.raf img {
  animation-name: rraf;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes rraf{
  0%{
      padding: 0px;
  }
  50%{
      padding: 10px;
  }
  100%{
      padding: 0px;
  }
}
.dd , .cc , .con {
  padding-top: 30px;
  padding-bottom: 30px;
}
.dd .item {
  margin-bottom: 20px;
}

.xs{
  padding: 40px 0px;
}
.xs p{
  margin-bottom: 0px;
}
.coo{
  border: 1px solid black;
  margin: 5px 0px;
  padding: 10px 10px;
}
/* .scrollable-container {
  width: 100%; 
  overflow-x: auto;
  white-space: nowrap; 
}

.scroll-content {
  display: inline-block; 
  } */
.t {
  background: linear-gradient(92.37deg, #D2F8FF 0%, rgba(223, 250, 255, .46) 100%);
  box-shadow: 0 2px 4px #00000008;
  padding: 30px 0px;
}
.t .aaa{
  border-right: 1px solid #000;
}
.t h3{
  font-weight: 400;
}
/* .t .aaaa{
  background:linear-gradient(136deg,#75cd25 2%,#25cd71 64%);
} */
.t form{
  padding: 10px;
  /* border: 1px solid black; */
}
.t .fform{
  border-radius: 5px;
  background: #fff;
  padding: 5px;
  border: 1px solid black;
}
.t img {
  background: #fff;
  border-radius: 100%;
  padding: 2px;
}
































































/* ----------------------------------- QUESTION CSS --------------------- */
:not(:root):fullscreen::backdrop {
  position: fixed;
  inset: 0px;
  background: none; /* Change 'none' to remove the black background */
} 
@media(max-width:2560px){
  .paad{
      padding: 0px;
  }
  
  /* body {
      margin: 0;
      padding: 0;
      height: 100vh;
      overflow: hidden;
      
  } */

  .cc9 {
      height: 1017px;
  }
  .a{
      margin: 5px;
  }
  .background1 {
      background: url('a4.png');
      background-size: contain;
      height: 54px;
      width: 100%;
  }
  .background2 {
      background: url('a2.png');
      background-size: contain;
      height: 54px;
      width: 100%;
  }
  .background3 {
      background: url('a3.png');
      background-size: contain;
      height: 54px;
      width: 100%;
  }
  .background4 {
      background: url('a5.png');
      background-size: contain;
      height: 54px;
      width: 100%;
  }
  .background5 {
      background: url('a6.png');
      background-size: contain;
      height: 54px;
      width: 100%;
  }

  .A22 {
      border: 1px solid black;
      padding: 15px;
      height: 550px;
      overflow-x: hidden;
      background: #e3f7fd;
  }
  .how{
      height: 66px;
      font-size: 22px;
      background-repeat: no-repeat;
      background-position: center;
      /* background: url('a4.png');
      background-size: cover;
      width: 100%; */
  }
  .a .rr1{
      background:#ededed;
      padding: 10px 10px;
      position: absolute;
      width: 100%;
      bottom: 0px;
      border-top: 1px solid black;
  }
  .rr1 p{
      margin: 0px;
  }
  .aaa{
      padding: 10px 0px;
  }
  .aaa .hh5{
      padding-top: 0px;
      font-size: 15px;
      color:#353535;
      font-weight: 900;
  }
  .aaa .hh6{
      padding-top: 0px;
      font-size: 14px;
      color:#353535;
      font-weight: 400;
  }
  .b{
      background: #353535;
  }
  .b p{
      color:#ffcf8f;
      margin: 0px;
      padding: 5px;
  }
  .A11 h5{
      font-size: 13px;
  }
  .q{
      height:745px;
  }
}

@media(max-width:1920px){
  .A22 {
      height: 90px;
      
  }
  .cc9 {
      height: 691px;
      
  }
  .cst {
    overflow-x: hidden;
    width: 100%;
    height: 449px;
}
.cstt {
  overflow-x: hidden;
  width: 100%;
  height: 245px;
}
  
}

@media(max-width:1440px){
  .pankajj {
      font-size: 25px;
      margin-top: -5px;
      margin-left: -25px;
  }
  .cc9 {
      height: 610px;
  }
  .A22 {
      height: 100px;
  }

  .cst {
    overflow-x: hidden;
    width: 100%;
    height: 375px;
}
.cstt {
  overflow-x: hidden;
  width: 100%;
  height: 245px;
}
}
@media(max-width:1024px){
  .A22 {
      height: 268px;
  }
  .pankajj {
      font-size: 17px;
      margin-top: -12px;
      margin-left: -25px;
  }
  .b .col-md-2 .col-md-3{
      padding:0px!important;
  }
  .cc9 {
      height: 650px;
  }
  .cst {
    overflow-x: hidden;
    width: 100%;
    height: 400px;
}
.cstt {
  overflow-x: hidden;
  width: 100%;
  height: 245px;
}
}
@media(max-width:768px){
  #fullscreenElement{
      display: block;
  }
  .cc9 {
      height: 700px;
  }
}
@media(max-width:576px){
  .cc9 {
      height: 700px;
  }
}
@media(max-width:425px){
  .cc9 {
      height:700px;
  }
}
@media(max-width:375px){
  .cc9 {
      height: 700px;
  }
}
@media(max-width:320px){
  .cc9 {
      height: 700px;
  }
}


/* ----------------------------------------------------------------------PANKAJSIR------------------------------------------------------------------------------------- */
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

@media(min-width: 2560px) {
  
  .mobile {
    display: none;
  }
}

@media(min-width: 1440px) {
  .mobile {
    display: none;
  }
}

@media(min-width: 1024px) {
  .mobile {
    display: none;
  }
}

@media(min-width: 580px) {
  .mobile {
    display: none;
  }
}

@media(max-width: 576px) {
  .desktop {
    display: none
  }
}

@media (max-width: 425px) {
  .desktop {
    display: none
  }
}

@media (max-width: 375px) {
  .desktop {
    display: none
  }
}

@media (max-width: 320px) {
  .desktop {
    display: none
  }
}

.menutop {
  /* background: red; */
  padding: 10px 0px;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255) !important;
  border-color: rgb(109 106 121) !important;
  background: radial-gradient(#ffffffe6, #7963d2) !important;
}

.search-input {
  padding-right: 2.5rem;
  /* Adjust based on icon size */
}

.search-icon {
  position: absolute;
  right: 10px;
  /* Adjust to position the icon */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure the icon doesn't interfere with typing */
}

@media(max-width:2560px) {
  .contact {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.contact .contact-information {
    min-height: 150px;
    margin: 0 0 30px 0;
    padding: 30px 15px 0 15px;
    background:#7963d200;
}

.contact .contact-info {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.contact .contact-icon {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#7963d2;
    border-radius: 50px;
}

.contact .contact-icon i {
    font-size: 18px;
    color: #ffffff;
}

.contact .contact-text {
    position: relative;
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.contact .contact-text h3 {
    font-size: 18px;
    font-weight: 700;
    color: #117347;
}

.contact .contact-text p {
    margin: 0;
    font-size: 16px;
    color: #454545;
}

.contact .contact-social a {
       margin-right: 10px;
    font-size: 24px;
    color: #00a3ff;
}

.contact .contact-social a:hover {
    color: #719a0a;
}

.contact .contact-form iframe {
    width: 100%;
    height: 380px;
    border-radius: 15px;
    margin-bottom: 25px;
}

.contact .contact-form input {
    padding: 15px;
    background: none;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.contact .contact-form textarea {
    height: 150px;
    padding: 8px 15px;
    background: none;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.contact .help-block ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
  .srimg {
    margin-top: -62px;
}

  /* .vcvc .uu{
    padding-top: 10px;
    background: red;
  } */
  .news .uc {
    padding-top: 10px;
    background: linear-gradient(92deg, #2dc977, #aef5d600);
    border-radius: 20px 0px 0px 20px;
  }

  .news span {
    font-weight: 800;
  }

  .newses {
    background: linear-gradient(92deg, #bfddcd, #aef5d600);
    padding: 50px 0px;
  }

  .newses .abt {
    border: 7px solid #ffffff;
    background: linear-gradient(181deg, white, #e8dbee);
    margin-top: 40px;
    border-radius: 20px;
    padding: 30px 13px;
  }

  .newses .uc {
    padding-top: 10px;
    background: linear-gradient(92deg, #2dc977, #aef5d600);
    border-radius: 20px 0px 0px 20px;
  }

  .newses span {
    font-weight: 800;
  }

  .rr .uc {
    padding-top: 10px;
    background: linear-gradient(92deg, #b798f1, #aef5d600) !important;
    border-radius: 20px 0px 0px 20px;
  }

  .rr span {
    font-weight: 800;
  }





  .vcvc .uc {
    padding-top: 10px;
    background: linear-gradient(92deg, #1c8adb, #aef5d600);
    border-radius: 20px 0px 0px 20px;
  }

  .vcvc span {
    font-weight: 800;
  }

  .lloo .popp {
    font-Weight: 700;
    color: #7963d2;
    font-Size: 17px;
  }

  .sticky-top {
    position: sticky !important;
    top: 0 !important;
    z-index: 1020 !important;
    /* background: red; */
  }

  .Apogo {
    width: 35% !important;
  }

  /* .active{
    border-top:2px solid red;
  } */
  .menutop {
    background: url(../public/28.png);
  }

  /* nav{
    padding-left: 0px!important;
  } */
  /* .menutop img {
    width: 70%;
  } */

  .menutop p {
    color: #5c5b5c;
    font-weight: 600;
    font-size: 20px;
  }

  .menutop input {
    border-radius: 20px;
    background: #fff;
    color: black;
  }

  .menutop .ii {
    padding: 0px;
  }

  nav {
    /* background:#9591a85c; */
    z-index: 9;
  }

  nav {
    background: rgb(121, 99, 210);
    padding: 10px 190px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 20px;
    /* padding-left: 50px; */
  }

  .navbar-light .navbar-nav .active>.nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show>.nav-link {
    color: rgb(255 255 255);
  }

  .carro {
    position: relative;
    margin-top: -62px;
  }

  .lloo {
    padding: 30px 0px;
  }

  .news {
    background: #b798f1;
    padding: 50px 0px;
  }

  .news .abt {
    border: 7px solid #ffffff;
    background: linear-gradient(181deg, white, #e8dbee);
    margin-top: 40px;
    border-radius: 20px;
    padding: 30px 13px;
  }

  .abt h5 {
    color: #5b5b5b;
    padding: 5px 0px;
    font-weight: 700;
  }

  .oo {
    position: absolute;
    margin-top: -22px;
    background: linear-gradient(45deg, #5056c9, #9383e8);
    padding: 6px 20px;
    border-radius: 16px;
    color: #fff;
    font-weight: 500;
    margin-left: 41px;
  }

  .iimm {
    padding: 50px 0px;
  }

  .iimm h3 {
    padding-top: 45px;
    font-size: 31px;
  }

  .iimm h6 {
    font-size: 23px;
  }

  .iimm h5 {
    font-size: 20px;
  }

  .iimm .btn {
    margin-top: 30px;
    margin-left: 25px;
  }

  .zoom {
    transition: transform .2s;
    background: linear-gradient(270deg, #7963d24d 3%, transparent 100%);
    border-radius: 10px;
  }

  .zoom:hover {
    transform: scale(1.0);
    border:1px solid #7963d2;
    border-radius: 10px;
    background: linear-gradient(90deg, #7963d24d 3%, transparent 100%);
    


  }

  .welcome {
    padding: 50px 0px;
  }

  .welcome .we {
    font-size: 40px;
    font-family: 'Satisfy', cursive;
  }

  .welcome .indi {
    line-height: 20px;
    font-weight: 600;
    font-size: 50px;
    padding: 0px;
    margin: 0px;
    color: #5b5b5b;
    /* font-family: 'Satisfy', cursive; */
  }

  .welcome .in {
    font-size: 50px;
    font-weight: 600;
    color: #5b5b5b;
    margin-bottom: 0px;
  }

  .welcome .for {
    font-size: 30px;
    font-weight: 600;
    color: #000;
  }

  .welcome .dow {
    font-size: 27px;
    font-weight: 600;
    color: #000;
  }

  .vcvc {
    padding: 40px 5px;
    background: #f0e3ff;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #b798f1;
    border-radius: 20px;
    font-weight: 600;
  }

  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0.25rem;
    background: #dbdbdb;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 20px;
  }

  nav i {
    /* display: none!important; */
    padding-right: 10px;
  }

  footer {
    background: #16402d;
    padding: 30px 0px;
  }

  footer p {
    color: #dbdbdb;
  }

  footer h6 {
    color: #e8dbee;
  }

  footer h5 {
    color: #e8dbee;
    font-size: 17px;
  }

  .copyy {
    background: #2b2a29;
  }

  .bggb {
    background: url(../public/28.png);
  }

  .bggb .pp1 {
    color: #000;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
  }

  .bggb .pp2 {
    color: #5057cc;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0px;

    padding-bottom: 5px;
  }

  .bggb .pp3 {
    color: #000;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 5px;
  }

  footer img {
    width: 14%;
  }

  .aza {
    padding: 40px 0px;
  }

  .aza h3 {
    padding-top: 20px;
    color: #5056c9;
    font-weight: 700;
  }

  .aza .bh1 {
    background: url(../public/27.png);
    border-radius: 20px;
    padding: 15px 5px;
  }

  .aza .bh2 {
    background: url(../public/28.png);
    border-radius: 20px;
    padding: 15px 5px;
  }

  .wew {
    display: none;
  }











}

@media(max-width:1920px) {}

@media(max-width:1440px) {}

@media(max-width:1024px) {
  .srimg {
    margin-top: -58px;
}
  .lloo h6 {
    font-size: 13px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 14px;
    /* padding-left: 50px; */
    padding: 0px;
  }

  .carro {
    margin-top: -57px;
  }

  .Apogo {
    width: 70% !important;
  }

  .menutop p {
    font-size: 14px;
  }

  nav ul {
    background: rgb(121, 99, 210);
    padding: 10px 5px;
  }

  .oo {
    margin-top: -22px;
    padding: 6px 10px;
    margin-left: 28px;
  }
}

@media(max-width:768px) {
  nav i {
    display: block !important;
  }
}

@media(max-width:576px) {
  .srimg {
    margin-top: 0px;
    padding: 10px;
}
  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 18px;
    /* padding-left: 50px; */
    padding: 0px;
  }

  .uu {
    font-size: 21px;
  }

  .lloo h6 {
    font-size: 15px;
  }

  .ewe {
    display: none !important;
  }

  .sticky-top {
    background: #7963d2;
  }

  .welcome .we {
    font-size: 35px;
  }

  .welcome .indi {
    font-weight: 700;
    font-size: 40px;
  }

  .welcome .in {
    font-size: 40px;
    font-weight: 700;
  }

  .welcome .for {
    font-size: 27px;
    font-weight: 600;
  }

  .menutop p {
    font-size: 18px;
  }

  .bggb {
    padding-left: 10px;
    padding-right: 10px;
  }

  footer img {
    width: 30%;
  }

  .iimm .btn {
    margin-top: 9px;
    margin-left: 0px;
  }

  .carro {
    margin-top: 0px;
  }

  nav {
    background: rgb(121, 99, 210);
  }

  .oo {

    margin-top: -24px;
    padding: 6px 80px;
    margin-left: 64px;
  }
}

@media(max-width:425px) {
  .oo {
    margin-top: -25px;
    padding: 6px 50px;
    margin-left: 49px;
  }
}

@media(max-width:375px) {
  .lloo h6 {
    font-size: 13px;
  }

  .oo {

    padding: 6px 30px;

  }
}

@media(max-width:320px) {
  .oo {
    padding: 5px 10px;
  }
}